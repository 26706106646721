import Table from '../components/table/Table.vue'; //表格
import Frame from '../components/frame/Frame.vue'; //框架主体部份
import DatePicker from '../components/datepicker/Index.vue'; //时间选择
import MPageHeader from '../components/pageHeader/PageHeader.vue'; //头部
import formSkeleton from '../components/formSkeleton/FormSkeleton.vue'; //骨架屏
import { App } from '@vue/runtime-dom';

export function registComponent(app: App) {
  app.component('m-table', Table);
  app.component('m-frame', Frame);
  app.component('m-date-picker', DatePicker);
  app.component('m-page-header', MPageHeader);
  app.component('m-form-skeleton', formSkeleton);
}

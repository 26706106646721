<template>
  <div class="m-form-skeleton">
    <el-skeleton animated :count="count" :loading="loading">
      <template #template>
        <div v-if="type === 'form'">
          <div class="skeleton-wrap" style="padding-bottom: 14px">
            <el-skeleton-item variant="h3" style="width: 100px; margin: 10px 0" />
            <el-skeleton-item variant="button" style="width: 500px; display: block" />
          </div>
          <div class="skeleton-wrap" style="padding-bottom: 14px">
            <el-skeleton-item variant="h3" style="width: 100px; margin: 10px 0" />
            <el-skeleton-item
              variant="text"
              style="width: 300px; display: block; margin-bottom: 10px"
            />
            <el-skeleton-item variant="text" style="width: 500px; display: block" />
          </div>
        </div>
        <div v-else-if="type === 'goods'" class="goods-skeleton" :style="{ display: 'flex' }">
          <el-skeleton-item variant="image" style="width: 70px; height: 70px; border-radius: 4px" />
          <div class="goods-skeleton-detail">
            <el-skeleton-item variant="p" style="width: 160px" />
            <el-skeleton-item variant="text" style="width: 220px" />
            <el-skeleton-item variant="text" style="width: 260px" />
          </div>
        </div>
        <div v-else-if="type === 'text'" class="text-skeleton">
          <el-skeleton-item variant="text" style="width: 600px" />
          <el-skeleton-item variant="text" style="width: 600px" />
          <el-skeleton-item variant="text" style="width: 400px" />
          <el-skeleton-item variant="text" style="width: 300px" />
          <el-skeleton-item variant="text" style="width: 250px" />
        </div>
        <div v-else-if="type === 'mini-text'" class="mini-text-skeleton">
          <el-skeleton-item variant="text" style="width: 150px" />
          <el-skeleton-item variant="text" style="width: 150px" />
          <el-skeleton-item variant="text" style="width: 80px" />
        </div>
      </template>

      <template #default>
        <slot></slot>
      </template>
    </el-skeleton>
  </div>
</template>

<script lang="ts">
import { PropType } from '@vue/runtime-core';

type Type = 'form' | 'goods' | 'text' | 'mini-text';

export default {
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    count: {
      type: Number as PropType<number>,
    },
    type: {
      type: String as PropType<Type>,
      default: 'form',
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-skeleton {
  display: flex;
  .goods-skeleton-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 0;
    padding-left: 16px;
  }
}
.text-skeleton {
  display: flex;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
}
.mini-text-skeleton {
  display: flex;
  height: 80px;
  flex-direction: column;
  justify-content: space-between;
}
</style>

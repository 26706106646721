<template>
  <div class="m-frame">
    <template v-if="isLogin">
      <section class="header-wrap">
        <header>
          <h3 class="app-title">
            <i class="m-frame-iconfont icon-yingyong"></i>
            <i>{{ title }}</i>
          </h3>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            v-if="deleteApi"
            @click="deleteApp"
            :loading="delLoading"
          >
            删除应用
          </el-button>
        </header>
      </section>
      <section class="main-wrap">
        <aside>
          <h4 class="menu-title">应用导航</h4>
          <el-menu :default-active="getRoute(curPath)" router>
            <template v-for="item in routeList" :key="item.path">
              <el-submenu :index="getRoute(item.path)" v-if="item.children">
                <template #title>
                  <i :class="'nav-icon ' + item.iconClass" v-if="item.iconClass"></i>
                  <span>{{ item.title }}</span>
                </template>
                <template v-for="child in item.children" :key="child.path">
                  <el-menu-item :index="child.path">{{ child.title }}</el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item :index="getRoute(item.path)" v-if="!item.children">
                <i :class="'nav-icon ' + item.iconClass" v-if="item.iconClass"></i>
                <template #title>{{ item.title }}</template>
              </el-menu-item>
            </template>
          </el-menu>
        </aside>
        <main>
          <div class="main-content">
            <!-- 对部分组件做缓存 -->
            <router-view v-slot="{ Component }" v-if="aliveInclude" :key="refreshTag">
              <keep-alive :include="aliveInclude">
                <component :is="Component"></component>
              </keep-alive>
            </router-view>

            <router-view v-slot="{ Component }" v-else-if="aliveExclude" :key="refreshTag">
              <keep-alive :exclude="aliveExclude">
                <component :is="Component"></component>
              </keep-alive>
            </router-view>

            <router-view v-else :key="refreshTag" />
          </div>
        </main>
      </section>
    </template>
    <template v-else>
      <div class="no-login">
        <div class="result-wrap">
          <el-result
            icon="warning"
            title="登录已过期"
            sub-title="请从应用中心重新登录！"
          ></el-result>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, ref, watchEffect, h, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import { ElMessageBox, ElMessage } from 'element-plus';

import { getRoute, getStoreId, getPluginName, getDomain } from '../../tools/config';
import { getUrlParams, changeURLParams } from '../../tools';
import request, { setRequestApi } from '../../tools/request';
import { TypedMessageParams } from 'element-plus/lib/el-message/src/types';

interface RouteListItem {
  path: string;
  iconClass: string;
  title: string;
  children: RouteListChild[];
}

interface RouteListChild {
  path: string;
  title: string;
}

export default {
  props: {
    routeList: {
      type: Array as PropType<RouteListItem[]>,
      required: true,
    },
    aliveInclude: {
      type: String as PropType<string>,
      default: '',
    },
    aliveExclude: {
      type: String as PropType<string>,
      default: '',
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    deleteApi: {
      type: String as PropType<string>,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { state, commit } = useStore();
    setRequestApi();

    const isLogin = ref(false);
    // 设置 token
    if (getUrlParams('token')) {
      const path = `/${getStoreId()}/${getPluginName()}`;
      Cookies.set('token', getUrlParams('token'), {
        expires: 2,
        path,
      });

      commit('setToken', getUrlParams('token'));

      changeURLParams('token', '');
      isLogin.value = true;
    } else if (Cookies.get('token')) {
      isLogin.value = true;
    }

    // 例如：在term-list-config页面时，应该使菜单栏中路径为term-list的选项高亮
    const curPath = computed(() => {
      let ret = '';
      // 返回 routerList 中的所有 与 route.path 匹配的元素
      const pathList = props.routeList.filter(item => {
        return new RegExp(item.path).test(route.path);
      });

      // 返回 pathList 中最长的路由作为当前路由
      for (let i = 0; i < pathList.length; i++) {
        const val = pathList[i].path;

        if (val.length > ret.length) {
          ret = val;
        }
      }
      return ret;
    });

    // console.log(state)

    watchEffect(() => {
      if (!state.isToken) {
        ElMessageBox({
          title: '未登录',
          message: '登录已过期，请从应用中心重新登录！',
          showCancelButton: false,
          showConfirmButton: false,
          showClose: false,
          type: 'warning',
          lockScroll: false,
        })
          .then()
          .catch();
      }
    });

    const delLoading = ref(false);
    const refreshTag = ref(0); // 值发生变化时重新加载界面
    const deleteApp = () => {
      ElMessageBox({
        title: '删除应用',
        message: h('div', { style: { paddingLeft: '16px' } }, [
          h(
            'h5',
            { style: { margin: '0px 0 14px', color: '#000' } },
            '删除本应用后，您将无法继续使用插件的功能',
          ),
          h('ul', { style: { 'line-height': 1.5 } }, [
            h('li', { style: { fontWeight: 'bold', color: '#333' } }, '删除须知:'),
            h('li', null, '1. 删除本应用并不会影响购物商城的任何数据'),
            h('li', null, '2. 删除本应用仅会删除本应用上的功能以及数据'),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const res = (await request({
            url: props.deleteApi,
            loading: delLoading,
          })) as {
            status: number;
          };

          if (res.status) {
            refreshTag.value++;

            ElMessage.success({
              message: '应用删除成功',
            } as TypedMessageParams<'success'>);
          }
        })
        .catch();
      emit('delete');
    };

    getDomain().then(res => commit('setStoreDomain', res));
    commit('setStoreId', getStoreId());

    return {
      curPath,
      deleteApp,
      getRoute,
      isLogin, // 全局提示 未登录
      state,
      delLoading,
      refreshTag,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool.scss';
@import './iconfont';

.m-frame {
  .header-wrap {
    header {
      height: 50px;
      background-color: $theme;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      box-shadow: $sShadow;
      .app-title {
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: bold;

        i.m-frame-iconfont {
          margin-right: 16px;
          font-size: 28px;
          font-weight: normal;
        }
      }

      .el-button--primary {
        border-color: rgba($color: #fff, $alpha: 0.4);
      }
    }
  }
  .main-wrap {
    display: flex;
    align-items: flex-start;
    aside {
      flex: 0 0 200px;
      margin: 30px 20px;
      margin-right: 0;
      padding: 10px 0 20px;
      background-color: #fff;
      min-height: 150px;
      box-shadow: $miniShadow;

      .menu-title {
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-weight: bold;
      }

      .el-menu {
        border-right: none;

        .el-menu-item.is-active {
          position: relative;
          background-color: $lightTheme;
        }
        .el-menu-item.is-active::before {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: block;
          height: 100%;
          border-left: 3px solid $theme;
        }
      }
      .nav-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
        height: 18px;
        line-height: 1;
      }
    }
    main {
      flex: 1;
      padding: 30px 20px;
      padding-bottom: 0px;
      max-height: calc(100vh - 80px);
      overflow: auto;
      .main-content {
        box-shadow: $miniShadow;
        background-color: #fff;
      }
    }
  }
  .no-login {
    position: fixed;
    display: flex;
    top: 0;
    right: 0px;
    left: 0px;
    bottom: 0px;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    .result-wrap {
      width: 600px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      border-radius: $sr;
    }
  }
}
</style>

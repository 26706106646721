import { useRoute } from 'vue-router';
import { getStoreInfo } from './api';

/**
 * 获取接口的域名（只能在组件内使用）
 * @returns 拼接后的域名
 */
export function api(): string {
  const protocol = location.protocol;
  return process.env.NODE_ENV === 'development'
    ? `${protocol}//${getStoreId()}-${getPluginName()}.cn01-apps.shopifp.com`
    : `${protocol}//${getStoreId()}-${getPluginName()}.${hrefPrefix()}-apps.shopifp.com`;

  function hrefPrefix(): string {
    let ret = 'cn01';
    const tar = location.href.split('//')[1].split('-').shift();
    if (tar === 'us01' || tar === 'us02') {
      ret = tar;
    }

    return ret;
  }
}

/**
 *  获取 店铺ID （只能在组件内使用）
 * @returns curPlugin 店铺ID
 */
export function getStoreId(): string {
  const route = useRoute();
  return route.params.storeId as string;
}

/**
 * 获取 插件名称 (只能在组件内使用)
 * @returns curPlugin 插件名称
 */
export function getPluginName(): string {
  const route = useRoute();

  const curPlugin = route.path.split('/')[2];

  return curPlugin;
}

/**
 *  获取拼接好的完整路由（只能在组件内使用）
 * @param {String} curRoute 该参数将与店铺ID、插件名拼接出完整的路由名
 * @returns router 可用于 routelink 进行跳转
 */
export function getRoute(curRoute: string): string {
  return `/${getStoreId()}/${getPluginName()}/${curRoute}`;
}

/**
 * 获取店铺商城域名（只能在组件内使用）
 * @returns 店铺商城前台域名
 */
export async function getDomain() {
  const res = await getStoreInfo({ store_id: getStoreId() });
  if (!res.status) {
    return;
  }
  return res.data.info.main_domain || res.data.info.shop_domain;
}

<template>
  <div class="m-table">
    <div class="m-table-wrap">
      <transition name="m-slide">
        <div
          class="m-table-control-bar"
          :style="{ height: controlBarHeight }"
          v-if="showControlBar && control && control.length"
        >
          <div class="m-table-control-btns">
            <el-button-group>
              <el-button size="small" class="checkbox-btn" @click="controlBarSelectChange">
                <span class="placeholder" style="margin-right: 26px" @click.stop>
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checked"
                    @change="controlBarSelectChange"
                  ></el-checkbox>
                </span>
                已选择 {{ selectCount }} 件
              </el-button>
              <el-button
                size="small"
                @click="$emit(control?.[0].on || '')"
                v-if="control[0].text"
                :loading="control[0].loading"
              >
                {{ control[0].text }}
              </el-button>
              <el-button size="small" @click="$emit(control?.[1].on || '')" v-if="control[1]">
                {{ control[0].text }}
              </el-button>
              <el-button size="small" @click.stop="controlBtnList" v-if="control.length >= 3">
                其他操作
                <i class="el-icon-caret-bottom"></i>
                <transition name="m-slide">
                  <div class="btn-list-wrap" v-show="slideShow" @click.stop>
                    <ul class="btn-list">
                      <template v-for="(item, index) in control" :key="item.on">
                        <li
                          @click="$emit(item.on)"
                          v-if="index >= 2"
                          :class="item.loading ? 'is-loading' : ''"
                        >
                          {{ item.text }}
                          <i :class="item.loading ? 'el-icon-loading' : ''"></i>
                        </li>
                      </template>
                    </ul>
                  </div>
                </transition>
              </el-button>
            </el-button-group>
          </div>
        </div>
      </transition>
      <!-- 表格 -->
      <el-table
        ref="elTable"
        :data="data"
        v-loading="loading"
        @selection-change="changeSelection"
        :header-cell-style="{
          backgroundColor: '#f6f6f6',
          borderBottom: 'none',
        }"
        @select-all="selectTableAll"
        @select="selectTableItem"
      >
        <template #empty>
          <el-empty description="没有任何数据"></el-empty>
        </template>
        <slot />
      </el-table>
    </div>
    <div class="m-table-pagination-wrap" v-if="pagination">
      <el-pagination
        background
        :layout="pageLayout"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizes"
        :current-page="pagination.currentPage"
        @current-change="currentChange"
        @next-click="nextClick"
        @prev-click="prevClick"
      />
    </div>
    <div class="m-table-empty-footer" v-else></div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';

interface ControlItem {
  on: string;
  text: string;
  loading?: boolean;
}

interface PaginationConf {
  total: number;
  pageSize: number;
  pageSizes?: number[];
  currentPage: number;
}

// interface TableProps {
//   data: any[];
//   loading: boolean;
//   pagination: PaginationConf;
//   control: ControlItem[];
// }

export default defineComponent({
  name: 'MTable',
  props: {
    data: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    pagination: {
      type: Object as PropType<PaginationConf>,
    },
    control: {
      type: Array as PropType<ControlItem[]>,
    },
  },
  // emits: ['current-change', 'next-click', 'prev-click', 'selection-change'],
  setup(props, { emit }) {
    const currentChange = cur => {
      emit('current-change', cur);
    };

    const nextClick = cur => {
      emit('next-click', cur);
    };

    const prevClick = cur => {
      emit('prev-click', cur);
    };

    const pageLayout = computed(() => {
      const pageSizes = props.pagination?.pageSizes;
      const hasPageSizes = pageSizes && pageSizes.length;
      return `total, ${hasPageSizes ? 'sizes,' : ''} prev, pager, next, jumper`;
    });

    const controlBarHeight = ref('47px');
    const setControlBarHeight = () => {
      if (document && document.querySelector('.m-table-wrap th')) {
        // @ts-ignore
        controlBarHeight.value = document.querySelector('.m-table-wrap th').clientHeight + 'px';
      }
    };

    const elTable = ref(); // element 表格实例
    const checked = ref(false); // control-bar 的复选框状态
    const showControlBar = ref(false); // control-bar 的显示隐藏
    const isIndeterminate = ref(false); // control-bar 的复选框状态 是否为模糊选择状态
    const selectCount = ref(0); // 当前选中的数据数量
    const changeSelection = selectList => {
      emit('selection-change', selectList);

      selectCount.value = selectList.length;

      if (selectList.length && !showControlBar.value) {
        showControlBar.value = true;
      }
    };
    // 表格全选时触发
    const selectTableAll = selectList => {
      if (selectList.length) {
        checked.value = true;
      } else {
        closeControlBar();
      }
    };
    // 表格单选时触发
    const selectTableItem = selectList => {
      if (selectList.length) {
        checked.value = true;
        isIndeterminate.value = true;
      } else {
        closeControlBar();
      }
      if (selectList.length === props.data.length) {
        isIndeterminate.value = false;
      }
    };

    const controlBarSelectChange = val => {
      // val 如果是 布尔类型，则说明是 点击的 checkbox
      if (typeof val === 'boolean') {
        if (checked.value) {
          closeControlBar();
        }
      } else {
        if (!checked.value) {
          closeControlBar();
        }
      }
      if (isIndeterminate.value) {
        checked.value = true;
        isIndeterminate.value = false;
      }
      elTable.value.toggleAllSelection();
    };

    function closeControlBar() {
      checked.value = false;
      showControlBar.value = false;
      isIndeterminate.value = false;
    }

    const slideShow = ref(false); // “其他操作”下拉框是否显示
    const controlBtnList = () => {
      slideShow.value = !slideShow.value;
    };

    const changeSlideShow = () => {
      slideShow.value = false;
    };

    const bindGlobal = () => {
      document.addEventListener('click', changeSlideShow);
    };

    const unbindGlobal = () => {
      document.removeEventListener('click', changeSlideShow);
    };

    return {
      changeSelection,
      currentChange,
      nextClick,
      prevClick,
      pageLayout,
      setControlBarHeight,
      controlBarHeight,
      showControlBar,
      checked,
      slideShow,
      controlBtnList,
      isIndeterminate,
      selectTableAll,
      selectTableItem,
      controlBarSelectChange,
      elTable,
      closeControlBar,
      selectCount,
      bindGlobal,
      unbindGlobal,
    };
  },
  mounted() {
    // @ts-ignore
    this.$nextTick(() => {
      // @ts-ignore
      this.setControlBarHeight();

      // 绑定的事件
      // @ts-ignore
      this.bindGlobal();
    });
  },
  beforeUnmount() {
    // @ts-ignore
    this.unbindGlobal();
  },
});
</script>

<style lang="scss" scoped>
.m-table {
  .m-table-wrap {
    position: relative;
    .m-table-control-bar {
      position: absolute;
      display: flex;
      align-items: center;
      background-color: #fff;
      top: 0px;
      width: 100%;
      z-index: 1000;
      background-color: #f6f6f6;
      padding-left: 6px;
      .el-button {
        height: 12px;
        font-size: 12px;
        &.checkbox-btn {
          padding-left: 7px;
          padding-top: 8px;
        }
        .el-checkbox {
          height: 12px;
        }
        .btn-list-wrap {
          position: absolute;
          background: #fff;
          width: 160px;
          left: 50%;
          top: 42px;
          transform: translateX(-50%);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid #e4e7ed;
          border-radius: 4px;

          &::after {
            content: '';
            display: block;
            position: absolute;
            border: 1px solid #e4e7ed;
            background-color: #fff;
            transform: rotate(45deg) translateX(-50%);
            width: 10px;
            height: 10px;
            left: 50%;
            top: -2px;
            z-index: -1;
          }
        }
        .btn-list {
          background: #fff;
          border-radius: 4px;
          padding: 6px 0;
          li {
            height: 34px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            color: #333;
            justify-content: space-between;

            &.is-loading {
              pointer-events: none;
              color: #999;
            }

            &:hover {
              background-color: #f5f7fa;
            }
          }
        }
      }
    }
  }
  .m-table-pagination-wrap {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #ebeef5;
  }
  .m-table-empty-footer {
    height: 60px;
    background-color: #f6f6f6;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }

  :deep(.el-table::before) {
    display: none;
  }
  :deep(.el-table__row:last-of-type td) {
    border-bottom: none;
  }
}
</style>


import { computed, ref, watchEffect, h, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import { ElMessageBox, ElMessage } from 'element-plus';

import { getRoute, getStoreId, getPluginName, getDomain } from '../../tools/config';
import { getUrlParams, changeURLParams } from '../../tools';
import request, { setRequestApi } from '../../tools/request';
import { TypedMessageParams } from 'element-plus/lib/el-message/src/types';

interface RouteListItem {
  path: string;
  iconClass: string;
  title: string;
  children: RouteListChild[];
}

interface RouteListChild {
  path: string;
  title: string;
}

export default {
  props: {
    routeList: {
      type: Array as PropType<RouteListItem[]>,
      required: true,
    },
    aliveInclude: {
      type: String as PropType<string>,
      default: '',
    },
    aliveExclude: {
      type: String as PropType<string>,
      default: '',
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
    deleteApi: {
      type: String as PropType<string>,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { state, commit } = useStore();
    setRequestApi();

    const isLogin = ref(false);
    // 设置 token
    if (getUrlParams('token')) {
      const path = `/${getStoreId()}/${getPluginName()}`;
      Cookies.set('token', getUrlParams('token'), {
        expires: 2,
        path,
      });

      commit('setToken', getUrlParams('token'));

      changeURLParams('token', '');
      isLogin.value = true;
    } else if (Cookies.get('token')) {
      isLogin.value = true;
    }

    // 例如：在term-list-config页面时，应该使菜单栏中路径为term-list的选项高亮
    const curPath = computed(() => {
      let ret = '';
      // 返回 routerList 中的所有 与 route.path 匹配的元素
      const pathList = props.routeList.filter(item => {
        return new RegExp(item.path).test(route.path);
      });

      // 返回 pathList 中最长的路由作为当前路由
      for (let i = 0; i < pathList.length; i++) {
        const val = pathList[i].path;

        if (val.length > ret.length) {
          ret = val;
        }
      }
      return ret;
    });

    // console.log(state)

    watchEffect(() => {
      if (!state.isToken) {
        ElMessageBox({
          title: '未登录',
          message: '登录已过期，请从应用中心重新登录！',
          showCancelButton: false,
          showConfirmButton: false,
          showClose: false,
          type: 'warning',
          lockScroll: false,
        })
          .then()
          .catch();
      }
    });

    const delLoading = ref(false);
    const refreshTag = ref(0); // 值发生变化时重新加载界面
    const deleteApp = () => {
      ElMessageBox({
        title: '删除应用',
        message: h('div', { style: { paddingLeft: '16px' } }, [
          h(
            'h5',
            { style: { margin: '0px 0 14px', color: '#000' } },
            '删除本应用后，您将无法继续使用插件的功能',
          ),
          h('ul', { style: { 'line-height': 1.5 } }, [
            h('li', { style: { fontWeight: 'bold', color: '#333' } }, '删除须知:'),
            h('li', null, '1. 删除本应用并不会影响购物商城的任何数据'),
            h('li', null, '2. 删除本应用仅会删除本应用上的功能以及数据'),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const res = (await request({
            url: props.deleteApi,
            loading: delLoading,
          })) as {
            status: number;
          };

          if (res.status) {
            refreshTag.value++;

            ElMessage.success({
              message: '应用删除成功',
            } as TypedMessageParams<'success'>);
          }
        })
        .catch();
      emit('delete');
    };

    getDomain().then(res => commit('setStoreDomain', res));
    commit('setStoreId', getStoreId());

    return {
      curPath,
      deleteApp,
      getRoute,
      isLogin, // 全局提示 未登录
      state,
      delLoading,
      refreshTag,
    };
  },
};

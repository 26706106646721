import request from '@/tools/request';

interface StoreInfoType {
  status: number;
  data: any;
}

export const getStoreInfo = (params: any): Promise<StoreInfoType> => {
  return request({
    url: '/component-store-info.html',
    params,
  }) as Promise<StoreInfoType>;
};

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import termgenerator from '@/app/termgenerator/router';
import giftcard from '@/app/giftcard/router';
import buypoints from '@/app/buypoints/router';
import pricecutsremind from '@/app/pricecutsremind/router';
import detailmarkettool from '@/app/detailmarkettool/router';
import imagesearch from '@/app/imagesearch/router';

const routes: Array<RouteRecordRaw> = [
  termgenerator,
  giftcard,
  buypoints,
  pricecutsremind,
  detailmarkettool,
  imagesearch,
];

export default createRouter({
  history: createWebHistory(),
  routes,
});

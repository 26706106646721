
import { computed, defineComponent, PropType, ref } from 'vue';

interface ControlItem {
  on: string;
  text: string;
  loading?: boolean;
}

interface PaginationConf {
  total: number;
  pageSize: number;
  pageSizes?: number[];
  currentPage: number;
}

// interface TableProps {
//   data: any[];
//   loading: boolean;
//   pagination: PaginationConf;
//   control: ControlItem[];
// }

export default defineComponent({
  name: 'MTable',
  props: {
    data: {
      type: Array as PropType<any[]>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    pagination: {
      type: Object as PropType<PaginationConf>,
    },
    control: {
      type: Array as PropType<ControlItem[]>,
    },
  },
  // emits: ['current-change', 'next-click', 'prev-click', 'selection-change'],
  setup(props, { emit }) {
    const currentChange = cur => {
      emit('current-change', cur);
    };

    const nextClick = cur => {
      emit('next-click', cur);
    };

    const prevClick = cur => {
      emit('prev-click', cur);
    };

    const pageLayout = computed(() => {
      const pageSizes = props.pagination?.pageSizes;
      const hasPageSizes = pageSizes && pageSizes.length;
      return `total, ${hasPageSizes ? 'sizes,' : ''} prev, pager, next, jumper`;
    });

    const controlBarHeight = ref('47px');
    const setControlBarHeight = () => {
      if (document && document.querySelector('.m-table-wrap th')) {
        // @ts-ignore
        controlBarHeight.value = document.querySelector('.m-table-wrap th').clientHeight + 'px';
      }
    };

    const elTable = ref(); // element 表格实例
    const checked = ref(false); // control-bar 的复选框状态
    const showControlBar = ref(false); // control-bar 的显示隐藏
    const isIndeterminate = ref(false); // control-bar 的复选框状态 是否为模糊选择状态
    const selectCount = ref(0); // 当前选中的数据数量
    const changeSelection = selectList => {
      emit('selection-change', selectList);

      selectCount.value = selectList.length;

      if (selectList.length && !showControlBar.value) {
        showControlBar.value = true;
      }
    };
    // 表格全选时触发
    const selectTableAll = selectList => {
      if (selectList.length) {
        checked.value = true;
      } else {
        closeControlBar();
      }
    };
    // 表格单选时触发
    const selectTableItem = selectList => {
      if (selectList.length) {
        checked.value = true;
        isIndeterminate.value = true;
      } else {
        closeControlBar();
      }
      if (selectList.length === props.data.length) {
        isIndeterminate.value = false;
      }
    };

    const controlBarSelectChange = val => {
      // val 如果是 布尔类型，则说明是 点击的 checkbox
      if (typeof val === 'boolean') {
        if (checked.value) {
          closeControlBar();
        }
      } else {
        if (!checked.value) {
          closeControlBar();
        }
      }
      if (isIndeterminate.value) {
        checked.value = true;
        isIndeterminate.value = false;
      }
      elTable.value.toggleAllSelection();
    };

    function closeControlBar() {
      checked.value = false;
      showControlBar.value = false;
      isIndeterminate.value = false;
    }

    const slideShow = ref(false); // “其他操作”下拉框是否显示
    const controlBtnList = () => {
      slideShow.value = !slideShow.value;
    };

    const changeSlideShow = () => {
      slideShow.value = false;
    };

    const bindGlobal = () => {
      document.addEventListener('click', changeSlideShow);
    };

    const unbindGlobal = () => {
      document.removeEventListener('click', changeSlideShow);
    };

    return {
      changeSelection,
      currentChange,
      nextClick,
      prevClick,
      pageLayout,
      setControlBarHeight,
      controlBarHeight,
      showControlBar,
      checked,
      slideShow,
      controlBtnList,
      isIndeterminate,
      selectTableAll,
      selectTableItem,
      controlBarSelectChange,
      elTable,
      closeControlBar,
      selectCount,
      bindGlobal,
      unbindGlobal,
    };
  },
  mounted() {
    // @ts-ignore
    this.$nextTick(() => {
      // @ts-ignore
      this.setControlBarHeight();

      // 绑定的事件
      // @ts-ignore
      this.bindGlobal();
    });
  },
  beforeUnmount() {
    // @ts-ignore
    this.unbindGlobal();
  },
});

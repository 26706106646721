export default {
  path: '/:storeId/buypoints/',
  component: () => import('@/app/buypoints/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/buypoints/views/Home.vue'),
    },
    {
      path: 'buy-points',
      component: () => import('@/app/buypoints/views/BuyPoints.vue'),
    },
    {
      path: 'buy-points-integral/:id?/:status?',
      component: () => import('@/app/buypoints/views/NewIntegral.vue'),
    },
    {
      path: 'selling-points',
      component: () => import('@/app/buypoints/views/SellingPoints.vue'),
    },
  ],
};

export default {
  path: '/:storeId/termgenerator/',
  component: () => import('@/app/termgenerator/Index.vue'),
  children: [
    {
      path: '',
      component: () => import('@/app/termgenerator/views/Home.vue'),
    },
    {
      path: 'term-list',
      component: () => import('@/app/termgenerator/views/TermList.vue'),
    },
    {
      path: 'term-list-config',
      component: () => import('@/app/termgenerator/views/TermListConfig.vue'),
    },
  ],
};


import { PropType } from '@vue/runtime-core';

type Type = 'form' | 'goods' | 'text' | 'mini-text';

export default {
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    count: {
      type: Number as PropType<number>,
    },
    type: {
      type: String as PropType<Type>,
      default: 'form',
    },
  },
};

<template>
  <div class="m-page-header">
    <h5 class="only-title" v-if="type === 'text'">{{ title }}</h5>
    <h5 class="link-header" v-else-if="type === 'link'">
      <router-link
        :to="getRoute(router)"
        class="m-page-header-iconfont icon-fanhui1 back-btn"
        v-if="router"
      ></router-link>
      <div class="m-page-header-iconfont icon-fanhui1 back-btn" @click="goBack" v-else></div>
      {{ title }}
    </h5>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { getRoute } from '../../tools/config';

type Type = 'text' | 'link';

export default {
  props: {
    type: {
      type: String as PropType<Type>,
      default: 'text',
      validator: (val: string) => {
        return ['text', 'link'].includes(val);
      },
    },
    router: {
      type: String as PropType<string>,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.back();
    };

    return {
      getRoute,
      goBack,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
@import './iconfont.scss';
.m-page-header {
  background-color: $lightGray;
  height: 60px;
  width: auto;

  .only-title {
    box-sizing: border-box;
    height: 100%;
    padding: 0 16px;
    min-width: 120px;
    background-color: #fff;
    border-top: 3px solid $theme;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .link-header {
    display: flex;
    align-items: center;
    .back-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      background-color: #eee;
      color: #999;
      font-size: 28px;
      margin-right: 16px;
      cursor: pointer;
    }
  }
}
</style>
